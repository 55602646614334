export default {
  name: 'ApplicationLoansMixin',

  methods: {
    getLoanRatio(application) {
      if (!application.require || application.purchase_price == 0) return '-';
      const data = (Number(application.purchase_price_loan_amount) / Number(application.purchase_price)) * 100;
      return this.calculateLoanRatio(data);
    },
    calculateLoanRatio(val) {
      return parseFloat(parseFloat(val).toFixed(3));
    },
    getDebtIncomeRatio(application) {
      /*
        formula:
            (expences * 12 * 100 ) / incomes
         */
      let incomes = 0;
      let expenses = 0;

      // CONISDER MORAGE TRANSACTION EXPENSES (require,purchase_price_monthly_payment)
      if (application.require) expenses = expenses + parseFloat(application.purchase_price_monthly_payment || 0);

      expenses = expenses + parseFloat(application.monthly_maintenance || 0);

      const incomeFields = [
        'income',
        'bonus',
        'securities_amount',
        'social_security',
        'k_income',
        'dividents',
        'investment_properties',
        'other_incomes',
      ];
      const expenceFields = [
        'vehicle_leases',
        'personal_loans',
        'student_loans',
        'mortgage_expenses',
        'alimonies',
        'child_supports',
        'other_expenses',
      ];

      for (const applicant of this.get(application, 'applicants.data', [])) {
        for (const field of incomeFields) {
          const data = this.get(applicant, `attributes.${field}`, 0);
          incomes = incomes + parseFloat(data);
        }
      }

      for (const field of expenceFields) {
        const items = this.get(application, `${field}.data`, []);
        for (const item of items) {
          const data = this.get(item, 'attributes.amount');
          expenses = expenses + parseFloat(data);
        }
      }

      if (incomes <= 0) return '-';
      const result = (expenses * 12 * 100) / incomes;
      console.log('result :>> ', result);
      return this.calculateDebtIncomeRatio(result);
    },
    calculateDebtIncomeRatio(val) {
      return val > 100 ? 100 : parseFloat(parseFloat(val).toFixed(3));
    },
  },
};
