<template>
  <div>
    <!--begin::Alert-->
    <template v-if="isCoordinator">
      <template v-for="(expired, index) in expireds">
        <div class="alert bg-light-danger d-flex align-items-center justify-content-between  px-5 mb-2" :key="index">
          <!--begin::Wrapper-->
          <div class="d-flex flex-column pe-0 pe-sm-10">
            <!--begin::Content-->
            <span>
              The application <code>{{ get(expired, 'id') }}</code>
              {{
                get(expired, 'attributes.days_to_left') == 0
                  ? 'is expred'
                  : get(expired, 'attributes.days_to_left') > 0
                  ? 'will be expred in ' + get(expired, 'attributes.days_to_left')
                  : 'was expred ' + Math.abs(get(expired, 'attributes.days_to_left')) + ' days ago'
              }}
            </span>
            <!--end::Content-->
          </div>
          <!--end::Wrapper-->

          <!--begin::Close-->
          <button
            type="button"
            class="btn btn-danger btn-sm"
            data-bs-dismiss="alert"
            @click="handleAutoRejectApplication(expired)"
          >
            {{ autoRejectText }}
          </button>
          <!--end::Close-->
        </div>
      </template>
    </template>

    <!--end::Alert-->
    <!-- <pre>{{ expireds }}</pre> -->
    <template v-if="loading_con">
      <div class="position-relative" style="height:400px;">
        <spinner bg-transparent />
      </div>
    </template>

    <cs-table
      v-else
      :search-attr="{ sm: '3' }"
      search-input-con-class=" "
      :filterable-attr="{ sm: '3' }"
      :loading="loading"
      :headers="headers"
      :items="items"
      :pagination="pagination"
      @filtered="handelFiltering"
      filterable-name="Status"
      filterable-key="status"
      :filterable-list="statuses"
    >
      <template #after-header-filterable>
        <b-col v-if="isCoordinator" sm="3" class="my-2 my-md-0">
          <div class="d-flex align-items-center">
            <label class="mr-3 mb-0 d-none d-md-block">Cooperatives:</label>
            <b-form-select
              v-model="filters.cooperative_id"
              :options="withViewAll(cooperatives)"
              @change="handleSelelction('cooperative_id', filters.cooperative_id)"
            />
          </div>
        </b-col>
        <b-col v-if="isCoordinator" sm="3" class="ml-auto my-2 my-md-0 text-right">
          <b-btn variant="light-primary" class="font-weight-bolder" @click="openInviteModal">
            <i class="fas fa-plus"></i>
            Invite Applicant
          </b-btn>
        </b-col>
      </template>

      <template #[`item.first_name`]="{ item }">
        <template v-if="getApplicants(item).length > 0">
          <template
            v-if="
              get(getApplicants(item, 0), 'attributes.first_name') ||
                get(getApplicants(item, 0), 'attributes.last_name')
            "
          >
            <span :id="`${item.id}_applicant`">
              {{
                get(getApplicants(item, 0), 'attributes.first_name') +
                  ' ' +
                  get(getApplicants(item, 0), 'attributes.last_name')
              }}
              <span v-if="getApplicants(item).length > 1" class="label label-rounded label-light-primary ml-2">
                +{{ getApplicants(item).length - 1 }}
              </span>
            </span>
            <b-tooltip :target="`${item.id}_applicant`" placement="top">
              {{ get(getApplicants(item, 0), 'attributes.email') }}
            </b-tooltip>
          </template>
          <template v-else>
            {{ get(getApplicants(item, 0), 'attributes.email') }}
          </template>

          <template
            v-if="
              item.attributes.status &&
                Number(item.attributes.days_to_left) > 0 &&
                item.attributes.status != 'Application Approved' &&
                item.attributes.status != 'Application Rejected'
            "
          >
            <br />
            <small
              :id="`${item.id}DaysLeft`"
              :class="{
                'text-muted': item.attributes.days_to_left > 15,
                'text-danger': item.attributes.days_to_left <= 15,
              }"
            >
              {{ item.attributes.days_to_left }} days left
            </small>
            <b-tooltip :target="`${item.id}DaysLeft`" placement="right">
              New York State requires an approval or rejection prior to 45 days of submission. AppExpress will
              automatically reject the application after 45 days. You only have
              {{ item.attributes.days_to_left }} left to approve or reject the applicant before the system will
              auto-reject the applicant.
            </b-tooltip>
          </template>
          <template
            v-if="
              get(item, 'attributes.days_to_left', null) != null &&
                Number(get(item, 'attributes.days_to_left', null)) <= 0
            "
          >
            <br />
            <span
              :id="`application_${item.id}_expired`"
              class="mt-1 label-light-danger white-space-nowrap label font-weight-bold label-lg label-inline"
            >
              <small :id="`${item.id}DaysLeft`" class="text-danger">
                Expired
              </small>
            </span>
            <b-tooltip :target="`${item.id}DaysLeft`" placement="right">
              {{
                item.attributes.days_to_left == 0
                  ? 'The application is expred'
                  : 'The application was expred ' + Math.abs(item.attributes.days_to_left) + ' days ago'
              }}
            </b-tooltip>
          </template>
        </template>
        <template v-else>
          No applicants
        </template>
      </template>

      <template #[`item.status`]="{ item }">
        <template v-if="item.attributes.status">
          <template
            v-if="
              get(item, 'attributes.days_to_left', null) != null &&
                Number(get(item, 'attributes.days_to_left', null)) <= 0 &&
                get(item, 'attributes.rejected')
            "
          >
            <span
              :id="`application_${item.id}`"
              class="label-light-danger white-space-nowrap label font-weight-bold label-lg label-inline"
            >
              {{ autoRejectStatusText }}
            </span>
          </template>
          <template v-else>
            <span
              :id="`application_${item.id}`"
              :class="[`label-light-${getBy(statuses, 'text', item.attributes.status).color}`]"
              class="white-space-nowrap label font-weight-bold label-lg label-inline"
            >
              {{ get(item, 'attributes.status') }}
            </span>

            <b-tooltip
              v-if="isReason(get(item, 'attributes.status')) && get(item, 'attributes.reason')"
              :target="`application_${item.id}`"
              placement="top"
            >
              {{ get(item, 'attributes.reason') }}
            </b-tooltip>
            <b-tooltip
              v-if="
                get(item, 'attributes.status') == 'Coordinator Review' && get(item, 'attributes.resubmited_at', null)
              "
              :target="`application_${item.id}`"
              placement="top"
            >
              The application re-submited at {{ get(item, 'attributes.resubmited_at', null) | moment('LLLL') }}
            </b-tooltip>

            <template v-if="isInterview(get(item, 'attributes.status'))">
              <p class="mt-1 mb-0">
                {{ get(item, 'attributes.last_interview_schedule.data.attributes.date') }}
                {{ get(item, 'attributes.last_interview_schedule.data.attributes.time') | moment('hh:mm A') }}
              </p>
            </template>
          </template>
        </template>

        <template v-else>-</template>
      </template>

      <template #[`item.id`]="{ item }">
        <code>{{ item.id }}</code>
      </template>

      <template #[`item.loan_to_value`]="{ item }">
        <template v-if="getLoanRatio(item.attributes) != '-'">
          <span
            class="font-weight-bolder"
            :class="[
              getLoanRatio(item.attributes) > get(item, 'attributes.cooperative.data.attributes.maximum_loan_ratio', 0)
                ? 'text-danger'
                : 'text-success',
            ]"
          >
            {{ getLoanRatio(item.attributes) + '%' }}
          </span>
        </template>
        <template v-else> - </template>
      </template>

      <template #[`item.debt_to_income`]="{ item }">
        <template v-if="getDebtIncomeRatio(item.attributes) != '-'">
          <span
            class="font-weight-bolder"
            :class="[
              getDebtIncomeRatio(item.attributes) >
              get(item, 'attributes.cooperative.data.attributes.maximum_debt_ratio', 0)
                ? 'text-danger'
                : 'text-success',
            ]"
          >
            {{ getDebtIncomeRatio(item.attributes) + '%' }}
          </span>
        </template>
        <template v-else> - </template>
      </template>

      <template #[`item.actions`]="{ item }">
        <b-dropdown
          v-if="hasViewAction(item) || getActions(item).length > 0"
          class="btn-icon"
          menu-class="w-max-content"
          variant="clean"
          right
          no-caret
        >
          <template v-slot:button-content>
            <i class="ki ki-bold-more-hor font-size-md pr-0"></i>
          </template>
          <ul class="navi navi-hover py-5">
            <li v-if="hasViewAction(item)" class="navi-item">
              <router-link :to="{ name: 'application-details', params: { id: item.id } }" v-slot="{ href, navigate }">
                <a class="navi-link" :href="href" @click="navigate">
                  <span class="navi-icon">
                    <i class="la la-eye font-size-h1 mr-2"></i>
                  </span>
                  <span class="navi-text">View</span>
                </a>
              </router-link>
            </li>

            <template v-for="(action, index) in getActions(item)">
              <li class="navi-item" :key="index">
                <a class="navi-link" href="javascript:;" @click="handleActionClick(action, item)">
                  <span class="navi-icon">
                    <i class="font-size-h1 mr-2" :class="action.icon"></i>
                  </span>
                  <span class="navi-text">{{ action.text }}</span>
                </a>
              </li>
            </template>
          </ul>
        </b-dropdown>
      </template>
    </cs-table>

    <b-modal v-model="invite_modal" centered footer-class="text-right">
      <template v-slot:modal-title>
        Invite Applicant
        <br />
        <small>Invite users to apply to cooperate</small>
      </template>

      <template v-slot:modal-footer>
        <b-btn variant="primary" class="text-uppercase" v-cs-loading="submiting" @click="onInviteApplicant()">
          Send Invite
        </b-btn>
      </template>

      <b-form-group description="You can send invite multiple applicants.">
        <template v-slot:label>
          Cooperatives
          <span class="text-danger">*</span>
        </template>
        <b-form-select
          v-model="invite_form.cooperative_id"
          :options="cooperatives"
          :state="validateState($v.invite_form.cooperative_id)"
          v-if="cooperatives.length"
        />
        <b-form-select v-else v-model="selected" disabled>
          <template #first>
            <b-form-select-option value="" disabled selected="selected">No Data</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group
        description="You can send invite multiple applicants LIKE (example1@gmail.com , example2@gmail.com)."
      >
        <template v-slot:label>
          Email
          <span class="text-danger">*</span>
        </template>
        <b-form-input v-model="invite_form.emails" :state="validateState($v.invite_form.emails)" />
      </b-form-group>
    </b-modal>

    <b-modal v-model="interview_modal" footer-class="text-right">
      <template v-slot:modal-title>
        Date and Time
      </template>
      <template v-slot:modal-footer>
        <b-btn variant="primary" class="text-uppercase" v-cs-loading="submiting" @click="handleInterviewSubmit">
          Schedule
        </b-btn>
      </template>
      <VueCtkDateTimePicker
        v-model="interview_form.date"
        label="Select Date & Time"
        :class="{ 'has-error': validateState($v.interview_form.date) === false }"
      />

      <b-form-invalid-feedback v-if="validateState($v.interview_form.date) === false" class="d-block">
        This field is required
      </b-form-invalid-feedback>
    </b-modal>

    <b-modal v-model="reason_modal" centered footer-class="text-right">
      <template v-slot:modal-title>
        {{ isApplicationAutoRejected ? autoRejectText : reason_form.status }}
      </template>

      <template v-slot:modal-footer>
        <b-btn variant="primary" class="text-uppercase" v-cs-loading="submiting" @click="onReasonSubmit()">
          Submit
        </b-btn>
      </template>

      <b-form-group
        v-if="!isApplicationRejected && !isApplicationAutoRejected"
        description="Please write extended description to this action"
      >
        <template v-slot:label>
          Description
          <!-- <span class="text-danger">*</span> -->
        </template>
        <b-form-textarea type="text" v-model="reason_form.reason" :state="validateState($v.reason_form.reason)" />
      </b-form-group>

      <b-form-group v-if="isApplicationRejected || isApplicationAutoRejected">
        <template v-slot:label>
          File
          <span class="text-danger">*</span>
        </template>
        <b-btn variant="light-primary" class="font-weight-bolder" @click="focusFileInput(`file_upload`)">
          <i class="fas fa-file"></i>
          Upload file
        </b-btn>
        <input
          type="file"
          style="display: none;"
          :ref="`file_upload`"
          accept="application/pdf"
          @change="handleFileUploaded"
        />
        <div v-if="file_url" class="mt-2 d-flex align-items-center">
          <span class="form-text  mr-5">
            Uploaded file
          </span>
          <a :href="file_url" class="mr-5" target="_blank">
            <i class="flaticon2-download"></i>
          </a>
        </div>
        <b-form-invalid-feedback style="display:block" v-if="validateState($v.reason_form.rejection_document) == false">
          File is required
        </b-form-invalid-feedback>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import applicationLoans from '@/mixins/application-loans';
import applicationActions from '@/mixins/application-actions';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'applications',
  mixins: [applicationLoans, applicationActions],
  validations() {
    return {
      invite_form: {
        cooperative_id: { required },
        emails: { required },
      },
      interview_form: {
        application_id: { required },
        date: { required },
      },
      reason_form: {
        id: { required },
        status: { required: this.isApplicationAutoRejected ? false : required },
        reason: {},
        rejection_document: {
          required: this.isApplicationRejected || this.isApplicationAutoRejected ? required : false,
        },
      },
    };
  },
  data() {
    return {
      selected: '',
      headers: [
        {
          text: 'Name',
          value: 'first_name',
          sortable: true,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Cooperative Name',
          value: 'attributes.cooperative.data.attributes.company_name',
          align: 'center',
        },
        {
          text: 'ID',
          value: 'id',
          align: 'center',
        },
        {
          text: 'Credit Score',
          value: 'attributes.min_credit_score',
          falsy: '-',
          align: 'center',
        },
        {
          text: 'Loan to Value',
          value: 'loan_to_value',
          align: 'center',
        },
        {
          text: 'Debt to Income',
          value: 'debt_to_income',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'right',
        },
      ],
      loading_con: false,
      loading: false,
      submiting: false,
      filters: {
        cooperative_id: null,
      },

      invite_modal: false,
      invite_form: {
        emails: '',
        cooperative_id: '',
      },

      interview_modal: false,
      interview_form: {
        application_id: '',
        date: '',
      },

      reason_modal: false,
      reason_form: {
        id: '',
        status: '',
        reason: '',
        rejection_document: '',
      },
      file_url: '',

      emails: {
        whitelist: [],
      },
      scheduleDate: null,
      inviteEmail: '',
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      cooperatives: 'resources/cooperatives',
      expireds: 'applications/expireds',
      items: 'applications/items',
      pagination: 'applications/pagination',
    }),
    isApplicationRejected() {
      return this.reason_form.status == 'Application Rejected';
    },
    isApplicationAutoRejected() {
      return this.reason_form.autoReject;
    },
  },

  created() {
    this.getmounted();
  },
  methods: {
    ...mapActions({
      getItems: 'applications/index',
      getExpireds: 'applications/expired',
      removeExpiredApplication: 'applications/removeExpiredApplication',
      getCooperatives: 'resources/cooperatives',
      updateApplicationStatus: 'applications/update',
      inviteItem: 'applicants/invite',
      createInterview: 'interview/create',
    }),
    focusFileInput(ref) {
      this.$refs[ref].click();
    },
    async handleFileUploaded(e) {
      const file = e.target.files.item(0);
      if (file) {
        this.reason_form.rejection_document = file;
        this.file_url = URL.createObjectURL(file);
      }
    },
    async getmounted() {
      this.loading_con = true;
      const query = this.$route.query;
      this.filters = { cooperative_id: query.cooperative_id || null };

      if (this.cooperatives.length == 0 && this.isCoordinator) await this.$await(this.getCooperatives());
      // this.getExpireds();
      await this.handleItems(() => {
        this.loading_con = false;
      });
    },
    async handelFiltering(filters, check = true) {
      const routeQuery = this.$route.query;

      filters = this.checkRouteForParams('cooperative_id', this.filters.cooperative_id, filters);

      const isSame = this.isSame(routeQuery, filters);

      if (check && isSame) return;

      if (check) await this.$router.replace({ query: filters });

      await this.handleItems();
    },
    handleItems: debounce(async function(e) {
      this.loading = true;

      const query = this.$_.cloneDeep(this.$route.query);
      query.elasticsearch = true;

      if (query.where == undefined) query.where = {};

      if (query.status != undefined) query.where.status = query.status;

      if (query.cooperative_id != undefined) query.where.cooperative_id = query.cooperative_id;

      if (query.per == undefined) query.per = 10;

      if (query.per == -1) query.per = Math.pow(10, 12);

      await this.$await(this.getItems(query));

      this.loading = false;
      if (typeof e == 'function') e();
    }, 400),

    async handleSelelction(key, val) {
      const filters = this.checkRouteForParams('page', 1);
      this.handelFiltering(filters);
    },

    checkRouteForParams(key, val, query = this.$route.query) {
      const filters = this.$_.cloneDeep(query);
      if (!val && filters[key] != undefined) delete filters[key];

      if (val) filters[key] = val;

      return filters;
    },

    withViewAll(data) {
      return [{ text: 'View All', value: null }, ...data];
    },

    getApplicants(application, index = null) {
      const applicants = this.get(application, 'attributes.applicants.data', []);
      return index != null ? applicants[index] : applicants;
    },

    getDayDiffer(date1, date2 = new Date()) {
      date1 = new Date(date1);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },

    openInviteModal() {
      this.initInviteForm();
      this.invite_modal = true;
    },

    initInviteForm(data = {}) {
      this.invite_form = {
        emails: '',
        cooperative_id: '',
      };
    },
    initInterviewForm(data = {}) {
      this.interview_form = {
        application_id: data.application_id || '',
        date: data.date || '',
      };
      this.$v.$reset();
    },
    initReasonForm(data = {}) {
      this.reason_form = {
        id: data.id || '',
        status: data.status || '',
        reason: '',
        rejection_document: '',
        autoReject: data.autoReject,
      };
      this.$v.$reset();
    },
    async onReasonSubmit() {
      if (!this.$v.reason_form.$invalid) {
        this.submiting = true;
        let params = {
          application: {
            id: this.reason_form.id,
            status: this.reason_form.status,
            reason: this.reason_form.reason,
            rejection_document: this.reason_form.rejection_document,
          },
        };

        if (!this.isApplicationRejected && !this.isApplicationAutoRejected) {
          delete params.application.rejection_document;
        }

        if (this.isApplicationAutoRejected) {
          delete params.application.status;
          delete params.application.reason;
          params.application.rejected = true;
        }

        params = this.jsonToFormData(params);

        try {
          await this.$await(this.updateApplicationStatus(params));

          if (this.isApplicationAutoRejected) this.removeExpiredApplication(this.reason_form.id);

          await this.handelFiltering(this.$route.query, false);

          this.reason_modal = false;
        } catch (error) {
          console.error('error :>> ', error);
        }

        this.submiting = false;
      } else {
        this.$v.reason_form.$touch();
      }
    },
    async handleInterviewSubmit() {
      if (!this.$v.interview_form.$invalid) {
        this.submiting = true;
        const time = this.$moment(this.interview_form.date).toISOString();

        const params = this.jsonToFormData({
          interview_schedule: {
            application_id: this.interview_form.application_id,
            date: this.interview_form.date,
            time,
          },
        });

        try {
          await this.$await(this.createInterview(params));

          await this.$await(
            this.updateApplicationStatus(
              this.jsonToFormData({
                application: { id: this.interview_form.application_id, status: 'Interview Scheduled' },
              })
            )
          );

          await this.handelFiltering(this.$route.query, false);

          this.interview_modal = false;
        } catch (error) {
          console.error('error :>> ', error);
        }

        this.submiting = false;
      } else {
        this.$v.interview_form.$touch();
      }
    },
    onInviteApplicant() {
      if (!this.$v.invite_form.$invalid) {
        this.submiting = true;

        const params = this.jsonToFormData({
          cooperative_id: this.invite_form.cooperative_id,
          emails: this.invite_form.emails.split(','),
        });

        this.inviteItem(params)
          .then((result) => {
            this.invite_modal = false;
            this.getmounted();
          })
          .finally(() => {
            this.submiting = false;
          });
      } else {
        this.$v.invite_form.$touch();
      }
    },

    handleActionClick(action, item) {
      document.body.click();
      const status = action.value || action.text;
      if (action.autoReject) {
        this.initReasonForm({ id: item.id, autoReject: true });
        this.reason_modal = true;
        return;
      }
      if (this.isInterview(status)) {
        this.initInterviewForm({ application_id: item.id });
        this.interview_modal = true;
      } else if (this.isReason(status)) {
        this.initReasonForm({ id: item.id, status });
        this.reason_modal = true;
      } else {
        this.updateApplicationStatus(
          this.jsonToFormData({
            application: { id: item.id, status },
          })
        ).then((result) => {
          this.handelFiltering(this.$route.query, false);
        });
      }
    },
    handleAutoRejectApplication(item) {
      this.initReasonForm({ id: item.id, autoReject: true });
      this.reason_modal = true;
    },
  },
};
</script>
